import React from 'react';

import Layout from '../../layout/CompanyLayout';
import IndexView from '../../views/company/IndexView';

const IndexPage = (): React.ReactElement => {
  return (
    <Layout>
      <IndexView />
    </Layout>
  );
};

export default IndexPage;
